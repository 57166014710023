import { ActionReducerMap, MetaReducer } from '@ngrx/store';
// Reducers
import * as fromConfigurationApi from './configuration-api/configuration-api.reducer';
import * as fromAlarmApi from './alarm-api/alarm-api.reducer';
import * as fromAlarmOverviewPage from './alarm-overview-page/alarm-overview-page.reducer';
import * as fromAlarmKPIPage from './alarm-kpi-page/alarm-kpi-page.reducer';
import * as fromAlarmReportsPage from './alarm-reports-page/alarm-reports-page.reducer';
import * as fromAlarmInvestigationsPage from './alarm-investigations-page/alarm-investigations-page.reducer';
import * as fromTopAlarmPage from './top-alarm-page/top-alarm-page.reducer';
import * as fromAutomaticDiagnosticReportPage from './automatic-diagnostic-report-page/automatic-diagnostic-report-page.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromSiteManagementApi from './site-management-api/site-management-api.reducer';
import * as fromProvisioningApi from './provisioning-api/provisioning-api.reducer';
import * as  fromDeviceStatisticsPage from './device-statistics-page/device-statistics-page.reducer';
import * as  fromProcessObjectConfig from './process-object-configuration/process-object-configuration.reducer';
export interface AppState {
  auth: fromAuth.AuthState;
  provisioning: fromProvisioningApi.ProvisioningApiState;
  siteManagement: fromSiteManagementApi.SiteManagementApiState;
  alarmApi: fromAlarmApi.AlarmApiState;
  alarmOverviewPage: fromAlarmOverviewPage.AlarmOverviewPageState;
  alarmKPIPage: fromAlarmKPIPage.AlarmKPIPageState,
  alarmReportsPage: fromAlarmReportsPage.AlarmReportsPageState;
  alarmInvestigationsPage: fromAlarmInvestigationsPage.AlarmInvestigationsPageState;
  topAlarmPage: fromTopAlarmPage.TopAlarmPageState;
  automaticDiagnosticReportPage: fromAutomaticDiagnosticReportPage.AutomaticDiagnosticReportPageState;
  configurationApi: fromConfigurationApi.ConfigurationApiState;
  deviceStatistics: fromDeviceStatisticsPage.DeviceStatisticsState;
  processObjectConfiguration: fromProcessObjectConfig.ConfigurationState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.reducer,
  provisioning: fromProvisioningApi.reducer,
  siteManagement: fromSiteManagementApi.reducer,
  alarmApi: fromAlarmApi.reducer,
  alarmOverviewPage: fromAlarmOverviewPage.reducer,
  alarmKPIPage: fromAlarmKPIPage.reducer,
  alarmReportsPage: fromAlarmReportsPage.reducer,
  alarmInvestigationsPage: fromAlarmInvestigationsPage.reducer,
  topAlarmPage: fromTopAlarmPage.reducer,
  automaticDiagnosticReportPage: fromAutomaticDiagnosticReportPage.reducer,
  configurationApi: fromConfigurationApi.reducer,
  deviceStatistics: fromDeviceStatisticsPage.reducer,
  processObjectConfiguration: fromProcessObjectConfig.reducer
};

export const metaReducers: MetaReducer<AppState>[] = [];
