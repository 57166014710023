import { createReducer, on } from '@ngrx/store';
import {
  IAlarmListResponse,
  IAlarmListTableData,
  IAutomaticDiagnosticEventDetail,
} from 'src/app/core/models/alarm.model';
import { AutomaticDiagnosticReportApiActions } from '../../actions/alarm-api.actions';
import { format } from 'date-fns';

export interface IAutomaticDiagnosticEventState {
  data: IAlarmListTableData<IAutomaticDiagnosticEventDetail>;
  isLoading: boolean;
  error: any;
  lastUpdated: string | undefined;
}

export const initialState: IAutomaticDiagnosticEventState = {
  data: {
    items:  null,
    nextCursor: '',
  },
  isLoading: true,
  error: undefined,
  lastUpdated: undefined,
};

export const reducer = createReducer(
  initialState,
  on(
    AutomaticDiagnosticReportApiActions.setEmptyAutomaticDiagnosticEvents,
    (state) => {
      return {
        ...state,
        data: {
          ...state.data,
          items: null,
          nextCursor: '',
        },
        error: undefined,
      };
    }
  ),
  on(
    AutomaticDiagnosticReportApiActions.loadAutomaticDiagnosticEvents,
    (state) => {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
  ),
  on(
    AutomaticDiagnosticReportApiActions.loadAutomaticDiagnosticEventsSuccess,
    (state, action) => {
      return {
        ...state,
        data: {
          items: state.data.items === null ? [...action.data.items] : [...state.data.items, ...action.data.items],
          nextCursor: action.data.nextCursor,
        },
        isLoading: false,
        lastUpdated: format(new Date(), 'dd/LL/yyyy HH:mm:ss')
      };
    }
  ),
  on(
    AutomaticDiagnosticReportApiActions.loadAutomaticDiagnosticEventsFailure,
    (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
  )
);
