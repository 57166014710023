import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, switchMap, map, of } from 'rxjs';
import { DeviceStatisticsApiService } from 'src/app/core/services/device-statistics-api/device-statistics-api.service';
import { DeviceStatisticsApiActions } from '../../actions/device-statistics-page-actions';

@Injectable()
export class DeviceStatisticsApiEffects {
  loadFtae$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadFtae),
      exhaustMap(
        ({ tenantId , siteId }) => this.configurationApi.getHierarchyDetails({ type: 'FTAE', tenantId,siteId }).pipe(
          map(
            data => DeviceStatisticsApiActions.loadFtaeSuccess(data)
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadFtaeFailure({ error }))
          )
        )
      )
    )
  );
  loadPiaf$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadPiaf),
      exhaustMap(
        ({ tenantId ,siteId}) => this.configurationApi.getHierarchyDetails({ type: 'PIAF', tenantId,siteId }).pipe(
          map(
            data => DeviceStatisticsApiActions.loadPiafSuccess(data)
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadPiafFailure({ error }))
          )
        )
      )
    )
  );
  loadDevicesDashboard$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadDevicesDashboard),
      switchMap(
        ({ payload }) => this.configurationApi.getDeviceOverView(payload).pipe(
          map(
            data => DeviceStatisticsApiActions.loadDevicesSuccess(data)
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadDevicesFailure({ error }))
          )
        )
      )
    )
  );

  loadDeviceControllerAlarmsChart$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadDeviceControllerAlarmsChart),
      switchMap(
        ({ payload }) => this.configurationApi.getDeviceControllerAlarmsChart(payload).pipe(
          map(
            data => DeviceStatisticsApiActions.loadDeviceControllerAlarmsChartSuccess(data)
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadDeviceControllerAlarmsChartFailure({ error }))
          )
        )
      )
    )
  );
  loadDeviceAlarmsChart$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadDeviceAlarmsChart),
      switchMap(
        ({ payload }) => this.configurationApi.getDeviceControllerAlarmsChart(payload).pipe(
          map(
            data => DeviceStatisticsApiActions.loadDeviceAlarmsChartSuccess(data)
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadDeviceControllerAlarmsChartFailure({ error }))
          )
        )
      )
    )
  );
  loadDevicesTable$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadDevicesTable),
      switchMap(
        ({ payload }) => this.configurationApi.getDeviceDetails(payload).pipe(
          map(
            data => DeviceStatisticsApiActions.loadDevicesTableSuccess(data)
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadDevicesTableFailure({ error }))
          )
        )
      )
    )
  );
  loadAlarmsTabTable$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadAlarmsTabTable),
      switchMap(
        ({ payload }) => this.configurationApi.getDeviceAlarms(payload).pipe(
          map(
            data => DeviceStatisticsApiActions.loadAlarmsTabSuccess(data)
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadDevicesTableFailure({ error }))
          )
        )
      )
    )
  );
  loadDevicesSummary$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadDevicesSummary),
      switchMap(
        ({ payload }) => this.configurationApi.getDeviceChildOverView(payload).pipe(
          map(
            data => DeviceStatisticsApiActions.loadDevicesSummarySuccess({data : data})
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadDevicesSummaryFailure({ error }))
          )
        )
      )
    )
  );

  loadDevicesInformationHead$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadDevicesInformationHead),
      exhaustMap(
        ({ payload }) => this.configurationApi.getDeviceInformationHead(payload).pipe(
          map(
            data => DeviceStatisticsApiActions.loadDevicesInformationHeadSuccess(data)
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadDevicesInformationHeadFailure({ error }))
          )
        )
      )
    )
  );

  loadDevicesInformation$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeviceStatisticsApiActions.loadDevicesInformation),
      exhaustMap(
        ({ payload }) => this.configurationApi.getDeviceInformation(payload).pipe(
          map(
            data => DeviceStatisticsApiActions.loadDevicesInformationSuccess(data)
          ),
          catchError(
            (error) => of(DeviceStatisticsApiActions.loadDevicesInformationFailure({ error }))
          )
        )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private configurationApi: DeviceStatisticsApiService,
  ) { }
}
