import { createActionGroup, props } from '@ngrx/store';
import { ValueType } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { AlarmEventType, IDetailedAlarmDetail, ToggleDateFilter } from 'src/app/core/models/alarm.model';
import { Shift } from 'src/app/core/models/configuration.model';

export const AlarmInvestigationsPageActions = createActionGroup({
  source: 'Alarms Investigations Page',
  events: {
    'Update Toggle Date Filter': props<{ toggleDateFilter: ToggleDateFilter }>(),
    'Set Date Filter': props<{ dateFilter: string }>(),
    'Set Area Filter': props<{ areaFilter: ValueType }>(),
    'Set Group Filter': props<{ groupFilter: ValueType }>(),
    'Set Shift Filter': props<{ shiftFilter: Shift }>(),
    'Set Report By': props<{ reportBy: AlarmEventType }>(),
    'Set Selected Detailed Alarm': props<{ selectedDetailedAlarm: IDetailedAlarmDetail }>(),
  }
});





