import { createActionGroup, props } from '@ngrx/store';
import { ValueType } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { AlarmState, ToggleDateFilter } from 'src/app/core/models/alarm.model';
import { Shift } from 'src/app/core/models/configuration.model';

export const TopAlarmPageActions = createActionGroup({
  source: 'Top Alarm Page',
  events: {
    'Update Toggle Date Filter': props<{ toggleDateFilter: ToggleDateFilter }>(),
    'Set Date Filter': props<{ dateFilter: string }>(),
    'Set Area Filter': props<{ areaFilter: ValueType }>(),
    'Set Group Filter': props<{ groupFilter: ValueType }>(),
    'Set Shift Filter': props<{ shiftFilter: Shift }>(),
    'Set Duration State Filter': props<{ value: AlarmState, index: number }>(),
    'Set Frequency State Filter': props<{ value: AlarmState, index: number }>(),
    'Set Duration State View': props<{ value: number, index: number }>(),
    'Set Frequency State View': props<{ value: number, index: number }>(),
  }
});





