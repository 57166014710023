import { Component, OnInit } from '@angular/core';
import { AuthService } from '@rockwell-automation-inc/service';

@Component({
  selector: 'px-logout-callback',
  templateUrl: './logout-callback.component.html',
})
export class LogoutCallbackComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.completeLogout();
    this.authService.login();
  }

}
