import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToolkitTranslateLoaderService } from './core/services/translate/toolkit-translate-loader.service';
// SKYHOOK
import { SkyhookDndModule } from '@ra-web-tech-ui-toolkit/skyhook-core';
import { createDefaultMultiBackend, HTML5ToTouch, SkyhookMultiBackendModule } from '@ra-web-tech-ui-toolkit/skyhook-multi-backend';
// AUTH0
import { AuthModule } from '@auth0/auth0-angular';
// STATE MANAGEMENT
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LayoutModule } from '@rockwell-automation-inc/layout';
import { ConfigService, ServiceConfigProvider, ServiceModule } from '@rockwell-automation-inc/service';
import { environment, IAppConfiguration } from 'src/environments/environment';
import { AuthInterceptor } from './core/interceptors/auth-interceptor/auth.interceptor';
import { AlarmApiEffects } from './state/effects/alarm-api/alarm-api.effects';
import { AlarmKPIEffects } from './state/effects/alarm-kpi/alarm-kpi.effects';
import { ConfigurationApiEffects } from './state/effects/configuration-api/configuration-api.effects';
import { DeviceStatisticsApiEffects } from './state/effects/device-statistics-effect/device-statistics.effects';
import { ProvisioningApiEffects } from './state/effects/provisioning-api/provisioning-api.effects';
import { SiteManagementApiEffects } from './state/effects/site-management-api/site-management-api.effects';
import { TopAlarmsByEffects } from './state/effects/top-alarms/top-alarms-by.effects';
import { TotalAlarmCountEffects } from './state/effects/total-alarm-count/total-alarm-count.effects';
import { metaReducers, reducers } from './state/reducers';

// AG GRID
import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { AG_GRID_LICENSE_KEY } from './shared/constants/ag-grid.constants';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

ModuleRegistry.registerModules(
  [
    ClientSideRowModelModule,
    InfiniteRowModelModule,
    ServerSideRowModelModule,
    ColumnsToolPanelModule,
    ClipboardModule,
    MenuModule,
    RangeSelectionModule,
    RowGroupingModule,
    SetFilterModule
  ]
);

@Injectable({ providedIn: 'root' })
export class ServiceConfigFromApp implements ServiceConfigProvider {
  constructor(private configService: ConfigService<IAppConfiguration>) {}
  get CoreConfiguration(): IAppConfiguration {
    return this.configService.config;
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AgGridModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SkyhookMultiBackendModule,
    SkyhookDndModule.forRoot({
      backend: createDefaultMultiBackend(),
      options: HTML5ToTouch,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: ToolkitTranslateLoaderService,
      },
      isolate: true,
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !environment.production }),
    EffectsModule.forRoot([
      ConfigurationApiEffects,
      AlarmApiEffects,
      DeviceStatisticsApiEffects,
      TotalAlarmCountEffects,
      TopAlarmsByEffects,
      AlarmKPIEffects,
      ProvisioningApiEffects,
      SiteManagementApiEffects,
    ]),
    AuthModule.forRoot(),
    LayoutModule,
    ServiceModule.forRoot(environment.appConfiguration),
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService<IAppConfiguration>) => () =>
        configService.setConfig(environment.appConfiguration),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
