import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../../actions/auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  dataMosaixToken: string | null;
  tenantId: string | null;
}

export const initialState: AuthState = {
  dataMosaixToken: null,
  tenantId: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.setDataMosaixToken, (state, action) => {
    return {
      ...state,
      dataMosaixToken: action.token,
    };
  }),
  on(AuthActions.setCurrentTenantId, (state, action) => {
    return {
      ...state,
      tenantId: action.tenantId,
    };
  }),
);

