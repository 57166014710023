import { format, isValid } from 'date-fns';
import { filter, lastValueFrom, Observable, take } from 'rxjs';
import { AlarmGroupBy } from 'src/app/core/models/alarm.model';
import { ResponseMapper } from 'src/app/core/models/general.model';
import { HOUR_MINUTE_DATE_FORMAT, MONTH_DAY_DATE_FORMAT } from '../constants/configuration.constants';
import moment, { Moment } from 'moment';
export function getValueFrom<T>(observable: Observable<T>): Promise<T> {
  const hasValue = (value: T) => value !== null && value !== undefined;
  return lastValueFrom(observable.pipe(filter(hasValue), take(1)));
}

export function formatResponseMapperData(
  groupBy: AlarmGroupBy,
  data: any[],
): any[] {
  let formatType: string;
  switch (groupBy) {
    case AlarmGroupBy.START_DATE:
      formatType = MONTH_DAY_DATE_FORMAT;
      break;
    case AlarmGroupBy.START_HOUR:
    case AlarmGroupBy.START_TEN_MINUTES_PERIOD:
      formatType = HOUR_MINUTE_DATE_FORMAT;
      break;
    default:
      formatType = '';
      break;
  }
  if (!formatType) return data;
  const newData: ResponseMapper[] = data.map(({ label, value, date }) => {
    const date_label = new Date(label);
    if (isValid(date_label)) {

      if(formatType === HOUR_MINUTE_DATE_FORMAT) {
        return {
          label: format(date_label, formatType),
          value,
          date
        };
      } else {
        const dtDateOnly = new Date(date_label.valueOf() + date_label.getTimezoneOffset() * 60 * 1000);
        return {
          label: format(dtDateOnly, formatType),
          value,
          date
        }
      }
    } else {
      return { label, value, date }
    }
  });
  return newData;
}
export function formatGroupedColResponseMapperData(
  timeLine: string | undefined,
  data: any[],
): any[] {
  if(timeLine === 'year') {
    return data;
  }
  let formatType: string;
  switch (timeLine) {
    case 'month':
    case 'week':
      formatType = MONTH_DAY_DATE_FORMAT;
      break;
    case 'day':
      formatType = HOUR_MINUTE_DATE_FORMAT;
      break;
    default:
      formatType = '';
      break;
  }
  if (!formatType) return data;
  const newData: ResponseMapper[] = data.map(({ label, value,high,low ,date }) => {
    const dateMomentObject = moment(label, "DD/MM/YYYY");
    const date_label = timeLine === 'day' ? new Date(label) : dateMomentObject.toDate();
    if (isValid(date_label)) {
      return {
        label: format(date_label, formatType),
        high,
        low,
        value,
        date
      }
    } else {
      return { label, value, date }
    }
  });
  return newData;
}

export function totalAlarmCountResponseMapperData(
  groupBy: AlarmGroupBy,
  data: any[],
  formatDates: boolean
): any[] {
  let formatType: string;
  switch (groupBy) {
    case AlarmGroupBy.START_DATE:
      formatType = MONTH_DAY_DATE_FORMAT;
      break;
    case AlarmGroupBy.START_HOUR:
    case AlarmGroupBy.START_TEN_MINUTES_PERIOD:
      formatType = HOUR_MINUTE_DATE_FORMAT;
      break;
    default:
      formatType = '';
      break;
  }
  if (!formatType) return data;
  if (!formatDates) return data;
  const newData: any[] = data.map((label) => {
    const date = new Date(label);
    if (isValid(date)) {if(formatType === HOUR_MINUTE_DATE_FORMAT) {
      return format(date, formatType);
    } else {
      const dtDateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
      return format(dtDateOnly, formatType);
    }
    } else {
      return label;
    }
  });
  return newData;
}
export function toCapitalCase(value: string): string {
  let newValue = value.toLowerCase();
  newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);
  return newValue;
}