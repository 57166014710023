import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap,switchMap, map, of } from 'rxjs';
import { IAlarm, IAlarmsByEventType, IAutomaticDiagnosticEventDetail } from 'src/app/core/models/alarm.model';
import { AlarmApiService } from 'src/app/core/services/alarm-api/alarm-api.service';
import { AlarmApiActions, AlarmsByEventTypeApiActions, AutomaticDiagnosticReportApiActions } from '../../actions/alarm-api.actions';



@Injectable()
export class AlarmApiEffects {

  loadAlarmList$ = createEffect(
    () => this.actions$.pipe(
      ofType(AlarmApiActions.loadAlarms),
      switchMap(
        ({ request }) => this.alarmApi.getAlarms<IAlarm>(request).pipe(
          map(
            data => AlarmApiActions.loadAlarmsSuccess({ data })
          ),
          catchError(
            (error) => of(AlarmApiActions.loadAlarmsFailure({ error }))
          )
        )
      )
    )
  );

  loadAlarmsByEventType$ = createEffect(
    () => this.actions$.pipe(
      ofType(AlarmsByEventTypeApiActions.loadAlarmsByEventType),
      switchMap(
        ({ request }) => this.alarmApi.getAlarms<IAlarmsByEventType>(request).pipe(
          map(
            data => AlarmsByEventTypeApiActions.loadAlarmsByEventTypeSuccess({ data })
          ),
          catchError(
            (error) => of(AlarmsByEventTypeApiActions.loadAlarmsByEventTypeFailure({ error }))
          )
        )
      )
    )
  );

  loadAutomaticDiagnosticEvents$ = createEffect(
    () => this.actions$.pipe(
      ofType(AutomaticDiagnosticReportApiActions.loadAutomaticDiagnosticEvents),
      switchMap(
        ({ request }) => this.alarmApi.getAlarms<IAutomaticDiagnosticEventDetail>(request).pipe(
          map(
            data => AutomaticDiagnosticReportApiActions.loadAutomaticDiagnosticEventsSuccess({ data })
          ),
          catchError(
            (error) => of(AutomaticDiagnosticReportApiActions.loadAutomaticDiagnosticEventsFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private alarmApi: AlarmApiService,
  ) { }
}
