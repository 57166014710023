import { createReducer, on } from '@ngrx/store';
import { ValueType } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { format } from 'date-fns';
import { AlarmEventType, IDetailedAlarmDetail, ToggleDateFilter } from 'src/app/core/models/alarm.model';
import { Shift } from 'src/app/core/models/configuration.model';
import { AlarmInvestigationsPageActions } from '../../actions/alarm-investigations-page.actions';

export const alarmFeatureKey = 'alarmReportsPage';

export interface AlarmInvestigationsPageState {
  dateFilter: string;
  toggleDateFilter: ToggleDateFilter | undefined;
  areaFilter: ValueType | undefined;
  groupFilter: ValueType | undefined;
  shiftFilter: Shift | null;
  eventType: AlarmEventType | undefined;
  selectedDetailedAlarm: IDetailedAlarmDetail | undefined;
}

export const initialState: AlarmInvestigationsPageState = {
  dateFilter: format(new Date(), 'MMM / dd / yyyy'),
  toggleDateFilter: ToggleDateFilter.MONTH,
  areaFilter: 'all',
  groupFilter: 'all',
  shiftFilter: null,
  eventType: AlarmEventType.DETAILED,
  selectedDetailedAlarm: undefined
};

export const reducer = createReducer(
  initialState,
  on(AlarmInvestigationsPageActions.updateToggleDateFilter, (state, action) => {
    return {
      ...state,
      toggleDateFilter: action.toggleDateFilter
    }
  }),
  on(AlarmInvestigationsPageActions.setDateFilter, (state, action) => {
    return {
      ...state,
      dateFilter: action.dateFilter,
    }
  }),
  on(AlarmInvestigationsPageActions.setAreaFilter, (state, action) => {
    return {
      ...state,
      areaFilter: action.areaFilter,
    }
  }),
  on(AlarmInvestigationsPageActions.setGroupFilter, (state, action) => {
    return {
      ...state,
      groupFilter: action.groupFilter,
    }
  }),
  on(AlarmInvestigationsPageActions.setShiftFilter, (state, action) => {
    return {
      ...state,
      shiftFilter: action.shiftFilter,
    }
  }),
  on(AlarmInvestigationsPageActions.setReportBy, (state, action) => {
    return {
      ...state,
      eventType: action.reportBy,
    }
  }),
  on(AlarmInvestigationsPageActions.setSelectedDetailedAlarm, (state, action) => {
    return {
      ...state,
      selectedDetailedAlarm: action.selectedDetailedAlarm,
    }
  }),
);

