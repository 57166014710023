import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { AlarmGroupBy } from 'src/app/core/models/alarm.model';
import { AlarmApiService } from 'src/app/core/services/alarm-api/alarm-api.service';
import { AlarmKPIChartsActions } from '../../actions/alarm-api.actions';

@Injectable()
export class AlarmKPIEffects {
  loadTopBadActors$ = createEffect(
    () => this.actions$.pipe(
      ofType(AlarmKPIChartsActions.loadTopBadActors),
      exhaustMap(
        ({ request }) => this.alarmApi.getTopBadActors(request).pipe(
          map(
            items => AlarmKPIChartsActions.loadTopBadActorsSuccess({
              items,
            })
          ),
          catchError(
            (error) => of(AlarmKPIChartsActions.loadTopBadActorsFailure({ error }))
          )
        )
      )
    )
  );

  loadFleetingAlarmCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(AlarmKPIChartsActions.loadFleetingAlarmCount),
      exhaustMap(
        ({ request }) => this.alarmApi.getAlarmsKPIs(request).pipe(
          map(
            data => AlarmKPIChartsActions.loadFleetingAlarmCountSuccess({
              data,
              groupBy: request.groupBy ?? AlarmGroupBy.START_DATE,
              timeLine: request.filters.timeLine
            })
          ),
          catchError(
            (error) => of(AlarmKPIChartsActions.loadFleetingAlarmCountFailure({ error }))
          )
        )
      )
    )
  );

  loadChatteringAlarmCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(AlarmKPIChartsActions.loadChatteringAlarmCount),
      exhaustMap(
        ({ request }) => this.alarmApi.getAlarmsKPIs(request).pipe(
          map(
            data => AlarmKPIChartsActions.loadChatteringAlarmCountSuccess({
              data,
              groupBy: request.groupBy ?? AlarmGroupBy.START_DATE,
              timeLine: request.filters.timeLine
            })
          ),
          catchError(
            (error) => of(AlarmKPIChartsActions.loadChatteringAlarmCountFailure({ error }))
          )
        )
      )
    )
  );

  loadStaleAlarmCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(AlarmKPIChartsActions.loadStaleAlarmCount),
      exhaustMap(
        ({ request }) => this.alarmApi.getAlarmsKPIs(request).pipe(
          map(
            data => AlarmKPIChartsActions.loadStaleAlarmCountSuccess({
              data,
              groupBy: request.groupBy ?? AlarmGroupBy.START_DATE,
              timeLine: request.filters.timeLine
            })
          ),
          catchError(
            (error) => of(AlarmKPIChartsActions.loadStaleAlarmCountFailure({ error }))
          )
        )
      )
    )
  );

  loadFloodingAlarmPercentage$ = createEffect(
    () => this.actions$.pipe(
      ofType(AlarmKPIChartsActions.loadFloodingAlarmPercentage),
      exhaustMap(
        ({ request }) => this.alarmApi.getAlarmsKPIs(request).pipe(
          map(
            data => AlarmKPIChartsActions.loadFloodingAlarmPercentageSuccess({
              data,
              groupBy: request.groupBy ?? AlarmGroupBy.START_DATE,
              timeLine: request.filters.timeLine
            })
          ),
          catchError(
            (error) => of(AlarmKPIChartsActions.loadFloodingAlarmPercentageFailure({ error }))
          )
        )
      )
    )
  );

  loadAverageAlarmRate$ = createEffect(
    () => this.actions$.pipe(
      ofType(AlarmKPIChartsActions.loadAverageAlarmRate),
      exhaustMap(
        ({ request }) => this.alarmApi.getAlarmsKPIs(request).pipe(
          map(
            data => AlarmKPIChartsActions.loadAverageAlarmRateSuccess({
              data,
              groupBy: request.groupBy ?? AlarmGroupBy.START_DATE,
              timeLine: request.filters.timeLine
            })
          ),
          catchError(
            (error) => of(AlarmKPIChartsActions.loadAverageAlarmRateFailure({ error }))
          )
        )
      )
    )
  );

  loadPreviousAverageAlarmRate$ = createEffect(
    () => this.actions$.pipe(
      ofType(AlarmKPIChartsActions.loadPreviousAverageAlarmRate),
      exhaustMap(
        ({ request }) => this.alarmApi.getAlarmsKPIs(request).pipe(
          map(
            data => AlarmKPIChartsActions.loadPreviousAverageAlarmRateSuccess({
              data,
              groupBy: request.groupBy ?? AlarmGroupBy.START_DATE,
              timeLine: request.filters.timeLine
            })
          ),
          catchError(
            (error) => of(AlarmKPIChartsActions.loadPreviousAverageAlarmRateFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private alarmApi: AlarmApiService,
  ) { }
}
