import { createReducer, on } from '@ngrx/store';
import { ITopAlarmBy } from 'src/app/core/models/alarm.model';
import { TopAlarmApiActions } from '../../actions/alarm-api.actions';
import { format } from 'date-fns';
export interface ITopAlarmLoadingState {
  duration: boolean;
  frequency: boolean;
}
export interface ITopAlarmErrorState {
  duration: any;
  frequency: any;
}

export interface ITopAlarmState {
  charts: ITopAlarmBy;
  loading: ITopAlarmLoadingState;
  error: ITopAlarmErrorState;
  lastUpdated?: string
}

export const initialState: ITopAlarmState = {
  charts: {
    duration: [],
    frequency: [],
  },
  loading: {
    duration: true,
    frequency: true,
  },
  error: {
    duration: undefined,
    frequency: undefined,
  },
  lastUpdated: undefined
};

export const reducer = createReducer(
  initialState,
  // TOP ALARMS BY DURATION
  on(TopAlarmApiActions.loadAlarmsByDuration, (state) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        duration: [],
      },
      loading: {
        ...state.loading,
        duration: true,
      },
      error: {
        ...state.error,
        duration: undefined,
      },
    };
  }),
  on(TopAlarmApiActions.loadAlarmsByDurationSuccess, (state, action) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        duration: [...action.data],
      },
      loading: {
        ...state.loading,
        duration: false,
      },
      lastUpdated: format(new Date(), 'dd/LL/yyyy HH:mm:ss')
    };
  }),
  on(TopAlarmApiActions.loadAlarmsByDurationFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        duration: false,
      },
      error: {
        ...state.error,
        duration: action.error,
      },
    };
  }),
  // TOP ALARMS BY FREQUENCY
  on(TopAlarmApiActions.loadAlarmsByFrequency, (state) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        frequency: [],
      },
      loading: {
        ...state.loading,
        frequency: true,
      },
      error: {
        ...state.error,
        frequency: undefined,
      },
    };
  }),
  on(TopAlarmApiActions.loadAlarmsByFrequencySuccess, (state, action) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        frequency: [...action.data],
      },
      loading: {
        ...state.loading,
        frequency: false,
      },
      lastUpdated: format(new Date(), 'dd/LL/yyyy HH:mm:ss')
    };
  }),
  on(TopAlarmApiActions.loadAlarmsByFrequencyFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        frequency: false,
      },
      error: {
        ...state.error,
        frequency: action.error,
      },
    };
  })
);
