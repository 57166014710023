import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, lastValueFrom, mergeMap } from 'rxjs';
import { getValueFrom } from 'src/app/shared/utils/general-utils';
import { AppState } from 'src/app/state/reducers';
import { AuthState } from 'src/app/state/reducers/auth/auth.reducer';
import { selectAuth } from 'src/app/state/selectors/auth.selectors';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  httpRequests: { [key: string]: { url: string; method: string } } = {};

  authState$ = this.store.select(selectAuth);

  constructor(
    private store: Store<AppState>,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.handleInterceptor(req, next);
  }

  handleInterceptor(req: HttpRequest<any>, next: HttpHandler) {
    if (this.requireDataMosaixToken(req)) {
      return this.store.select(selectAuth).pipe(
        first(),
        mergeMap((auth: AuthState) => {
          const newReq = req.clone({
            setHeaders: {
              'Authorization': `Bearer ${auth.dataMosaixToken}`,
              'Content-Type': `application/json`,
            },
          });
          return next.handle(newReq);
        }),
      );
    }
      return next.handle(req);
  }
  requireDataMosaixToken(req: HttpRequest<any>): boolean {
    const { url, method } = req;
    const extensionPaths = [
      { path: '/credentials', method: 'POST' },
      { path: '/provision', method: 'POST' },
      { path: '/info', method: 'GET' },
      { path: '/sites', method: 'POST' },
      { path: '/sites', method: 'PATCH' },
      { path: '/extractors', method: 'GET' },
      { path: '/getFuntionStatus', method: 'GET' },
      { path: '/getaccessstatus', method: 'GET' }
    ];
    const found = extensionPaths.find(
      extension => url.includes(extension.path) && extension.method === method
    );
    return url.includes('/api/v1/tenants') && Boolean(found);
  }

  async dataMosaixTokenHandler(req: HttpRequest<any>, next: HttpHandler) {
    this.httpRequests[req.url] = { url: req.url, method: req.method };
    if (this.httpRequests[req.url]) {
      const auth: AuthState = await getValueFrom(this.authState$);
      const newReq = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${auth.dataMosaixToken}`,
          'Content-Type': `application/json`,
        },
      });
      delete this.httpRequests[req.url];
      return lastValueFrom(next.handle(newReq));
    }
    this.httpRequests[req.url] = { url: req.url, method: req.method };
    return lastValueFrom(next.handle(req));
  }

}
