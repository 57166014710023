import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateHttpMultiLoader } from '@ra-web-tech-ui-toolkit/common-utils/cdk';

@Injectable()
export class ToolkitTranslateLoaderService extends TranslateHttpMultiLoader {
  constructor(http: HttpClient) {
    super(http, [
      {
        prefix: '@ra-web-tech-ui-toolkit/common-utils/assets/locals/',
        suffix: '.json'
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/form-system/assets/locals/',
        suffix: '.json'
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/navigation/assets/locals/',
        suffix: '.json'
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/common-views/assets/locals/',
        suffix: '.json'
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/grid/assets/locals/',
        suffix: '.json'
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/popups/assets/locals/',
        suffix: '.json'
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/lists/assets/locals/',
        suffix: '.json'
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/dashboard-controls/assets/locals/',
        suffix: '.json'
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/buttons/assets/locals/',
        suffix: '.json'
      },
      {
        prefix: '@ra-web-tech-ui-toolkit/experimental/assets/locals/',
        suffix: '.json'
      },
      // You can add your own translations here. You just have to set the right path
    ]);
  }
}
