import { combineReducers } from '@ngrx/store';
import * as fromAlarmKPI from './alarm-kpi.reducer';
import * as fromAlarmList from './alarm-list.reducer';
import * as fromAlarmsByEventType from './alarms-by-event-type.reducer';
import * as fromAutomaticDiagnosticReport from './automatic-diagnostic-report.reducer';
import * as fromTopAlarmBy from './top-alarm-by.reducer';
import * as fromTotalAlarmCount from './total-alarm-count.reducer';


export interface AlarmApiState {
  alarmGroupedBy: fromTotalAlarmCount.ITotalAlarmCountState;
  alarmKPICharts: fromAlarmKPI.IAlarmKPIState;
  topAlarmBy: fromTopAlarmBy.ITopAlarmState;
  alarms: fromAlarmList.IAlarmListState;
  alarmsByEventType: fromAlarmsByEventType.IAlarmsByEventTypeState;
  automaticDiagnosticReport: fromAutomaticDiagnosticReport.IAutomaticDiagnosticEventState;
}

export const reducer = combineReducers({
  alarmGroupedBy: fromTotalAlarmCount.reducer,
  topAlarmBy: fromTopAlarmBy.reducer,
  alarms: fromAlarmList.reducer,
  alarmsByEventType: fromAlarmsByEventType.reducer,
  automaticDiagnosticReport: fromAutomaticDiagnosticReport.reducer,
  alarmKPICharts: fromAlarmKPI.reducer,
});

