import { createActionGroup, props } from '@ngrx/store';
import { IAutomaticDiagnosticEventDetail } from 'src/app/core/models/alarm.model';

export const AutomaticDiagnosticReportPageActions = createActionGroup({
  source: 'Automation Diagnostic Report Page',
  events: {
    'Set Start Date': props<{ startDate: string }>(),
    'Set End Date': props<{ endDate: string }>(),
    'Set Selected Diagnostic Event': props<{ selectedDiagnosticEvent: IAutomaticDiagnosticEventDetail }>(),
  }
});





