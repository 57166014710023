import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@rockwell-automation-inc/service';
import { tap } from 'rxjs';

@Component({
  selector: 'px-callback',
  templateUrl: './callback.component.html',
})
export class CallbackComponent {
  navigate$ = this.auth.isAuthenticated$.pipe(
    tap(isAuthenticated => {
      if (!isAuthenticated) {
        this.auth.completeLogout();
        this.auth.login();
        return;
      }
      this.router.navigate(['/dashboard/callback']);
    }));

  constructor(
    public auth: AuthService,
    public router: Router,
  ) { }

}
