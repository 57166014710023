import { IErrorMessages } from '@ra-web-tech-ui-toolkit/common-utils/cdk';

export const GENERAL_ERROR_MESSAGES: IErrorMessages = {
  required:  'Field required'
};
export const MONTH_YEAR_DATE_ERROR = `Enter date in mmm / yyyy format e.g. Jan / 2023`;
export const MONTH_DAY_YEAR_DATE_ERROR = `Enter date in mmm / dd / yyyy format e.g. Jan / 01 / 2023`;

export const FTDM_NOT_FOUND_ERROR = 'PlantPAx Analytics requires an existing FactoryTalk DataMosaix subscription.';
export const FTDM_ROLES_ERROR = 'Add Application User, Data Configurator and Data Scientist roles in FactoryTalk DataMosaix to continue the process.';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong!';
export const EXTRACTORS_DATA_NOT_FOUND = `We couldn't bring the extractor information at this moment.`;

