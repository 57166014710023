import { createReducer, on } from '@ngrx/store';
import { IAlarm, IAlarmListTableData } from 'src/app/core/models/alarm.model';
import { AlarmApiActions } from '../../actions/alarm-api.actions';
import { format } from 'date-fns';

export interface IAlarmListState {
  data: IAlarmListTableData<IAlarm>;
  isLoading: boolean;
  error: any;
  lastUpdated?: string
}

export const initialState: IAlarmListState = {
  data: {
    items: null,
    nextCursor: '',
  },
  isLoading: true,
  error: undefined,
  lastUpdated: undefined,
};

export const reducer = createReducer(
  initialState,
  on(AlarmApiActions.setEmptyAlarms, (state) => {
    return {
      ...state,
      data: {
        ...state.data,
        items: null,
        nextCursor: '',
      },
      error: undefined,
    };
  }),
  on(AlarmApiActions.loadAlarms, (state) => {
    return {
      ...state,
      isLoading: true,
      error: undefined,
    };
  }),
  on(AlarmApiActions.loadAlarmsSuccess, (state, action) => {
    return {
      ...state,
      data: {
        items: state.data.items === null ? [...action.data.items] :[...state.data.items, ...action.data.items],
        nextCursor: action.data.nextCursor,
      },
      isLoading: false,
      lastUpdated: format(new Date(), 'dd/LL/yyyy HH:mm:ss')
    };
  }),
  on(AlarmApiActions.loadAlarmsFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  })
);
