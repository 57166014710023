import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { AlarmApiService } from 'src/app/core/services/alarm-api/alarm-api.service';
import { TotalAlarmCountApiActions } from '../../actions/alarm-api.actions';

@Injectable()
export class TotalAlarmCountEffects {

  loadTotalAlarmCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(TotalAlarmCountApiActions.loadTotalAlarmCount),
      exhaustMap(
        ({ request, groupBy }) => this.alarmApi.getAlarmsGroupedBy(request, groupBy).pipe(
          map(
            data => TotalAlarmCountApiActions.loadTotalAlarmCountSuccess({ data, groupBy })
          ),
          catchError(
            (error) =>
              of(TotalAlarmCountApiActions.loadTotalAlarmCountFailure({ error, groupBy }))
          )
        )
      )
    )
  );
  loadTotalAlarmClassCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(TotalAlarmCountApiActions.loadTotalAlarmClassCount),
      exhaustMap(
        ({ request, groupBy }) => this.alarmApi.getAlarmsGroupedBy(request, groupBy).pipe(
          map(
            data => TotalAlarmCountApiActions.loadTotalAlarmCountSuccess({ data, groupBy })
          ),
          catchError(
            (error) =>
              of(TotalAlarmCountApiActions.loadTotalAlarmCountFailure({ error, groupBy }))
          )
        )
      )
    )
  );
  loadTotalAlarmStateCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(TotalAlarmCountApiActions.loadTotalAlarmStateCount),
      exhaustMap(
        ({ request, groupBy }) => this.alarmApi.getAlarmsGroupedBy(request, groupBy).pipe(
          map(
            data => TotalAlarmCountApiActions.loadTotalAlarmCountSuccess({ data, groupBy })
          ),
          catchError(
            (error) =>
              of(TotalAlarmCountApiActions.loadTotalAlarmCountFailure({ error, groupBy }))
          )
        )
      )
    )
  );
  loadTotalAlarmPriorityCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(TotalAlarmCountApiActions.loadTotalAlarmPriorityCount),
      exhaustMap(
        ({ request, groupBy }) => this.alarmApi.getAlarmsGroupedBy(request, groupBy).pipe(
          map(
            data => TotalAlarmCountApiActions.loadTotalAlarmCountSuccess({ data, groupBy })
          ),
          catchError(
            (error) =>
              of(TotalAlarmCountApiActions.loadTotalAlarmCountFailure({ error, groupBy }))
          )
        )
      )
    )
  );
  loadTotalAlarmAreaCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(TotalAlarmCountApiActions.loadTotalAlarmAreaCount),
      exhaustMap(
        ({ request, groupBy }) => this.alarmApi.getAlarmsGroupedBy(request, groupBy).pipe(
          map(
            data => TotalAlarmCountApiActions.loadTotalAlarmCountSuccess({ data, groupBy })
          ),
          catchError(
            (error) =>
              of(TotalAlarmCountApiActions.loadTotalAlarmCountFailure({ error, groupBy }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private alarmApi: AlarmApiService,
  ) {}
}
