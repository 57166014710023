import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { AuthState } from '../reducers/auth/auth.reducer';

export const selectAuth = (state: AppState) => state.auth;

export const selectAuthTenantId = createSelector(
  selectAuth,
  (auth: AuthState) => auth.tenantId
);

export const selectAuthDataMosaixToken = createSelector(
  selectAuth,
  (auth: AuthState) => auth.dataMosaixToken
);

