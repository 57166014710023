import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlarmGroupBy, IAlarmGroupByResponse, IAlarmRequest, IAlarmListResponse, IAlarmKPIChart, IAlarmKPIItem, IAlarmListDownloadResponse } from 'src/app/core/models/alarm.model';
import { environment } from 'src/environments/environment';

const API_PREFIX = 'alarm';

@Injectable({
  providedIn: 'root'
})
export class AlarmApiService {

  constructor(private http: HttpClient) { }

  getAlarmsGroupedBy(request: IAlarmRequest, groupBy: AlarmGroupBy = AlarmGroupBy.START_DATE): Observable<IAlarmGroupByResponse[]> {
    request = { ...request, groupBy };
    return this.http.post<IAlarmGroupByResponse[]>(
      `${environment.apiURL}/${API_PREFIX}/getTotalAlarmCount`,
      request,
      { headers: this.getTenantIdHeader(request.tenantId) }
    );
  }
  getDrillDown<T>(request: IAlarmRequest): Observable<IAlarmListResponse<T>> {
    return this.http.post<IAlarmListResponse<T>>(
      `${environment.apiURL}/${API_PREFIX}/getDrillDown`,
      request,
      { headers: this.getTenantIdHeader(request.tenantId) }
    );
  }
  getAlarms<T>(request: IAlarmRequest): Observable<IAlarmListResponse<T>> {
    return this.http.post<IAlarmListResponse<T>>(
      `${environment.apiURL}/${API_PREFIX}/getAlarms`,
      request,
      { headers: this.getTenantIdHeader(request.tenantId) }
    );
  }

  getCsvDownload<T>(request: IAlarmRequest): Observable<IAlarmListDownloadResponse> {
    return this.http.post<IAlarmListDownloadResponse>(
      `${environment.apiURL}/${API_PREFIX}/getCsvDownload`,
      request,
      { headers: this.getTenantIdHeader(request.tenantId) }
    );
  }

  getAlarmsByDuration(request: IAlarmRequest): Observable<IAlarmGroupByResponse[]> {
    return this.http.post<IAlarmGroupByResponse[]>(
      `${environment.apiURL}/${API_PREFIX}/getAlarmsDuration`,
      request,
      { headers: this.getTenantIdHeader(request.tenantId) }
    );
  }

  getAlarmsByFrequency(request: IAlarmRequest): Observable<IAlarmGroupByResponse[]> {
    return this.http.post<IAlarmGroupByResponse[]>(
      `${environment.apiURL}/${API_PREFIX}/getAlarmsFrequency`,
      request,
      { headers: this.getTenantIdHeader(request.tenantId) }
    );
  }

  getAlarmsKPIs(request: IAlarmRequest): Observable<IAlarmKPIChart> {
    return this.http.post<IAlarmKPIChart>(
      `${environment.apiURL}/${API_PREFIX}/getAlarmsKPIs`,
      request,
      { headers: this.getTenantIdHeader(request.tenantId) }
    );
  }

  getTopBadActors(request: IAlarmRequest): Observable<IAlarmKPIItem[]> {
    return this.http.post<IAlarmKPIItem[]>(
      `${environment.apiURL}/${API_PREFIX}/getTopAlarms`,
      request,
      { headers: this.getTenantIdHeader(request.tenantId) }
    );
  }

  private getTenantIdHeader(tenantId: string): HttpHeaders {
    return new HttpHeaders().set('tenantId', tenantId);
  }
}
