import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IContract } from '../../models/provisioning.model';
import { IApplicationInfo, ICredentials, ISite, ISiteExtractorCredential, ISiteForm , IStatus } from '../../models/site-management.model';

@Injectable({
  providedIn: 'root'
})
export class SiteManagementApiService {

  constructor(private http: HttpClient) { }

  getApplicationInfo(tenantId: string, applicationId: string): Observable<IApplicationInfo> {
    return this.http.get<IApplicationInfo>(`${environment.apiURL}/tenants/${tenantId}/applications/${applicationId}/info`);
  }
  getFunctionStatus(tenantId: string): Observable<IStatus> {
    return this.http.get<IStatus>(`${environment.apiURL}/tenants/${tenantId}/getFuntionStatus`);
  }
  getContracts(tenantId: string): Observable<IContract[]> {
    return this.http.get<IContract[]>(`${environment.apiURL}/tenants/${tenantId}/contracts`);
  }

  regenerateApplicationCredentials(tenantId: string, applicationId: string): Observable<ICredentials> {
    return this.http.post<ICredentials>(`${environment.apiURL}/tenants/${tenantId}/applications/${applicationId}/credentials`, {});
  }

  regenerateExtractorCredentials(tenantId: string, extractorId: string): Observable<ICredentials> {
    return this.http.post<ICredentials>(`${environment.apiURL}/tenants/${tenantId}/extractors/${extractorId}/credentials`, {});
  }

  createSite(tenantId: string, site: ISiteForm): Observable<ISite> {
    return this.http.post<ISite>(`${environment.apiURL}/tenants/${tenantId}/sites`, site);
  }

  updateSite(tenantId: string, siteId: string, site: ISiteForm): Observable<ISite> {
    return this.http.patch<ISite>(`${environment.apiURL}/tenants/${tenantId}/sites/${siteId}`, site);
  }

  getExtractorData(tenantId: string, siteId: string): Observable<ISiteExtractorCredential[]> {
    return this.http.get<ISiteExtractorCredential[]>(`${environment.apiURL}/tenants/${tenantId}/sites/${siteId}/extractors`);
  }

  getSites(tenantId: string): Observable<ISite[]> {
    return this.http.get<ISite[]>(`${environment.apiURL}/tenants/${tenantId}/sites`);
  }
}
