import { createReducer, on } from '@ngrx/store';
import { ValueType } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { format } from 'date-fns';
import { ToggleDateFilter } from 'src/app/core/models/alarm.model';
import { Shift } from 'src/app/core/models/configuration.model';
import { AlarmReportsPageActions } from '../../actions/alarm-reports-page.actions';

export const alarmFeatureKey = 'alarmReportsPage';

export interface AlarmReportsPageState {
  dateFilter: string;
  toggleDateFilter: ToggleDateFilter | undefined;
  areaFilter: ValueType | undefined;
  groupFilter: ValueType | undefined;
  shiftFilter: Shift | null;
}

export const initialState: AlarmReportsPageState = {
  dateFilter: format(new Date(), 'MMM / dd / yyyy'),
  toggleDateFilter: ToggleDateFilter.MONTH,
  areaFilter: 'all',
  groupFilter: 'all',
  shiftFilter: null,
};

export const reducer = createReducer(
  initialState,
  on(AlarmReportsPageActions.updateToggleDateFilter, (state, action) => {
    return {
      ...state,
      toggleDateFilter: action.toggleDateFilter
    }
  }),
  on(AlarmReportsPageActions.setDateFilter, (state, action) => {
    return {
      ...state,
      dateFilter: action.dateFilter,
    }
  }),
  on(AlarmReportsPageActions.setAreaFilter, (state, action) => {
    return {
      ...state,
      areaFilter: action.areaFilter,
    }
  }),
  on(AlarmReportsPageActions.setGroupFilter, (state, action) => {
    return {
      ...state,
      groupFilter: action.groupFilter,
    }
  }),
  on(AlarmReportsPageActions.setShiftFilter, (state, action) => {
    return {
      ...state,
      shiftFilter: action.shiftFilter,
    }
  }),
);

