import { createReducer, on } from '@ngrx/store';
import { format, startOfMonth } from 'date-fns';
import { IAutomaticDiagnosticEventDetail } from 'src/app/core/models/alarm.model';
import { MONTH_DAY_YEAR_DATE_FORMAT } from 'src/app/shared/constants/configuration.constants';
import { AutomaticDiagnosticReportPageActions } from '../../actions/automatic-diagnostic-report-page.actions';

export const alarmFeatureKey = 'automaticDiagnosticReportPage';

export interface AutomaticDiagnosticReportPageState {
  startDate: string;
  endDate: string;
  selectedDiagnosticEvent: IAutomaticDiagnosticEventDetail;
}

export const initialState: AutomaticDiagnosticReportPageState = {
  startDate: format(startOfMonth(new Date()), MONTH_DAY_YEAR_DATE_FORMAT),
  endDate: format(new Date(), MONTH_DAY_YEAR_DATE_FORMAT),
  selectedDiagnosticEvent: {
    assessment: '',
    catalog: '',
    deviceName: '',
    message: '',
    state: '',
    eventTime: '',
    area: '',
    devicePath: '',
    diagnosticCode: '',
    majorRevision: '',
    minorRevision: '',
    productName: '',
    productType: '',
    resource: '',
    serverName: '',
    vendor: ''
  }
};

export const reducer = createReducer(
  initialState,
  on(AutomaticDiagnosticReportPageActions.setStartDate, (state, action) => {
    return {
      ...state,
      startDate: action.startDate,
    }
  }),
  on(AutomaticDiagnosticReportPageActions.setEndDate, (state, action) => {
    return {
      ...state,
      endDate: action.endDate,
    }
  }),
  on(AutomaticDiagnosticReportPageActions.setSelectedDiagnosticEvent, (state, action) => {
    return {
      ...state,
      selectedDiagnosticEvent: action.selectedDiagnosticEvent,
    }
  }),
);

