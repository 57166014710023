import { createReducer, on } from '@ngrx/store';
import { IError } from 'src/app/core/models/general.model';
import { IProvisioning } from 'src/app/core/models/provisioning.model';
import { ProvisioningApiActions } from '../../actions/provisioning-api.actions';
import { DEFAULT_ERROR_MESSAGE } from 'src/app/shared/constants/error.constants';

export interface ProvisioningApiState {
  provisioningStatus: IProvisioning | null;
  hasRoleAccess: boolean,
  isProvisioningInProgress: boolean;
  errorProvisioning: IError | null;
}

export const initialState: ProvisioningApiState = {
  provisioningStatus: null,
  isProvisioningInProgress: true,
  hasRoleAccess: true,
  errorProvisioning: null,
};

export const reducer = createReducer(
  initialState,
  on(ProvisioningApiActions.startProvisioning, (state) => {
    return {
      ...state,
      isProvisioningInProgress: true,
      errorProvisioning: null,
    }
  }),
  on(ProvisioningApiActions.startProvisioningSuccess, (state, action) => {
    return {
      ...state,
      isProvisioningInProgress: false,
      provisioningStatus: action.provisioning,
    };
  }),
  on(ProvisioningApiActions.startProvisioningFailure, (state, action) => {
    return {
      ...state,
      isProvisioningInProgress: false,
      errorProvisioning: action.error?.error || DEFAULT_ERROR_MESSAGE
    };
  }),

  on(ProvisioningApiActions.getAccessStatus, (state, action) => {
    return {
      ...state,
      hasRoleAccess: false
    };
  }),
  on(ProvisioningApiActions.getAccessStatusSuccess, (state, action) => {
    return {
      ...state,
      hasRoleAccess: action.status
    };
  }),
  on(ProvisioningApiActions.emptyProvisioning, (state) => {
    return {
      ...state,
      isProvisioningInProgress: false,
      errorProvisioning: null,
      provisioningStatus: null,
    };
  }),
);
