import { createActionGroup, props } from '@ngrx/store';
import { ValueType } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { ToggleDateFilterNew } from 'src/app/core/models/alarm.model';
import { Shift } from 'src/app/core/models/configuration.model';
import { IDatePickerModel } from 'src/app/components/custom-date-picker/type';
export const AlarmOverviewPageActions = createActionGroup({
  source: 'Alarms Overview Page',
  events: {
    'Update Toggle Date Filter': props<{ toggleDateFilter: ToggleDateFilterNew }>(),
    'Set Date Filter': props<{ dateFilter: IDatePickerModel }>(),
    'Set Area Filter': props<{ areaFilter: ValueType }>(),
    'Set Process Object Filter': props<{ processObjFilter: ValueType }>(),
    'Set Group Filter': props<{ groupFilter: ValueType }>(),
    'Set Shift Filter': props<{ shiftFilter: Shift }>(),
    'Reset Filters': props<{ refreshPage: boolean }>(),
  }
});
