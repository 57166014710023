import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, mergeMap, map, of } from 'rxjs';
import { SiteManagementApiService } from 'src/app/core/services/site-management-api/site-management-api.service';
import { SiteManagementApiActions } from '../../actions/site-management-api.actions';
import { ConfigurationApiActions } from 'src/app/state/actions/configuration-api.actions';

@Injectable()
export class SiteManagementApiEffects {

  loadApplicationInfo$ = createEffect(
    () => this.actions$.pipe(
      ofType(SiteManagementApiActions.loadApplicationInfo),
      exhaustMap(
        ({ tenantId, applicationInfo }) => this.siteManagementApi.getApplicationInfo(tenantId, applicationInfo).pipe(
          map(
            applicationInfo => SiteManagementApiActions.loadApplicationInfoSuccess({ applicationInfo })
          ),
          catchError(
            (error) => of(SiteManagementApiActions.loadApplicationInfoFailure({ error }))
          )
        )
      )
    )
  );
  loadContracts$ = createEffect(
    () => this.actions$.pipe(
      ofType(SiteManagementApiActions.loadContracts),
      exhaustMap(
        ({ tenantId }) => this.siteManagementApi.getContracts(tenantId).pipe(
          map(
            contracts => SiteManagementApiActions.loadContractsSuccess({ contracts })
          ),
          catchError(
            (error) => of(SiteManagementApiActions.loadContractsFailure({ error }))
          )
        )
      )
    )
  );

  regenerateExtractorCredentials$ = createEffect(
    () => this.actions$.pipe(
      ofType(SiteManagementApiActions.regenerateExtractorCredentials),
      exhaustMap(
        ({ tenantId, extractorId }) =>
          this.siteManagementApi.regenerateExtractorCredentials(tenantId, extractorId).pipe(
            map(
              credentials =>
                SiteManagementApiActions.regenerateExtractorCredentialsSuccess({ credentials, extractorId })
            ),
            catchError(
              (error) => of(SiteManagementApiActions.regenerateExtractorCredentialsFailure({ error }))
            )
          )
      )
    )
  );
  regenerateApplicationCredentials$ = createEffect(
    () => this.actions$.pipe(
      ofType(SiteManagementApiActions.regenerateApplicationCredentials),
      exhaustMap(
        ({ tenantId, applicationId }) =>
          this.siteManagementApi.regenerateApplicationCredentials(tenantId, applicationId).pipe(
            map(
              credentials =>
                SiteManagementApiActions.regenerateApplicationCredentialsSuccess({ credentials })
            ),
            catchError(
              (error) => of(SiteManagementApiActions.regenerateApplicationCredentialsFailure({ error }))
            )
          )
      )
    )
  );
  loadExtractorData$ = createEffect(
    () => this.actions$.pipe(
      ofType(SiteManagementApiActions.loadExtractorData),
      exhaustMap(
        ({ tenantId, siteId }) =>
          this.siteManagementApi.getExtractorData(tenantId, siteId).pipe(
            map(
              siteExtractorCredentials =>
                SiteManagementApiActions.loadExtractorDataSuccess({ siteExtractorCredentials })
            ),
            catchError(
              (error) => of(SiteManagementApiActions.loadExtractorDataFailure({ error }))
            )
          )
      )
    )
  );
  loadfunctionStatus$ = createEffect(
    () => this.actions$.pipe(
      ofType(SiteManagementApiActions.loadFunctionStatus),
      exhaustMap(
        ({ tenantId }) =>
          this.siteManagementApi.getFunctionStatus(tenantId).pipe(
            map(
              status => SiteManagementApiActions.loadFunctionStatusSuccess({ status })
            ),
            catchError(
              (error) => of(SiteManagementApiActions.loadFunctionStatusFailure({ error }))
            )
          )
      )
    )
  );
  createSite$ = createEffect(
    () => this.actions$.pipe(
      ofType(SiteManagementApiActions.createSite),
      exhaustMap(
        ({ tenantId, site }) =>
          this.siteManagementApi.createSite(tenantId, site).pipe(
            map(
              site =>
                SiteManagementApiActions.createSiteSuccess({ site })
            ),
            catchError(
              (error) => of(SiteManagementApiActions.createSiteFailure({ error }))
            )
          )
      )
    )
  );
  updateSite$ = createEffect(
    () => this.actions$.pipe(
      ofType(SiteManagementApiActions.updateSite),
      exhaustMap(
        ({ tenantId, siteId, site }) =>
          this.siteManagementApi.updateSite(tenantId, siteId, site).pipe(
            map(
              site =>
                SiteManagementApiActions.updateSiteSuccess({ site })
            ),
            catchError(
              (error) => of(SiteManagementApiActions.updateSiteFailure({ error }))
            )
          )
      )
    )
  );

  loadSites$ = createEffect(
    () => this.actions$.pipe(
      ofType(SiteManagementApiActions.loadSites),
      exhaustMap(
        ({ tenantId, resetSelectedSite }) =>
          this.siteManagementApi.getSites(tenantId).pipe(
            mergeMap(
              sites => [SiteManagementApiActions.loadSitesSuccess({ sites, resetSelectedSite }),
              ConfigurationApiActions.loadSpaces({ tenantId, dataSetId: sites[0]?.dataSetId })]
            ),
            catchError(
              (error) => of(SiteManagementApiActions.loadSitesFailure({ error }))
            )
          )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private siteManagementApi: SiteManagementApiService
  ) { }
}
