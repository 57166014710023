import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { AlarmApiService } from 'src/app/core/services/alarm-api/alarm-api.service';
import { TopAlarmApiActions } from '../../actions/alarm-api.actions';



@Injectable()
export class TopAlarmsByEffects {
  loadTopAlarmByDuration$ = createEffect(
    () => this.actions$.pipe(
      ofType(TopAlarmApiActions.loadAlarmsByDuration),
      exhaustMap(
        ({ request }) => this.alarmApi.getAlarmsByDuration(request).pipe(
          map(
            data => TopAlarmApiActions.loadAlarmsByDurationSuccess({ data })
          ),
          catchError(
            (error) => of(TopAlarmApiActions.loadAlarmsByDurationFailure({ error }))
          )
        )
      )
    )
  );
  loadTopAlarmByFrequency$ = createEffect(
    () => this.actions$.pipe(
      ofType(TopAlarmApiActions.loadAlarmsByFrequency),
      exhaustMap(
        ({ request }) => this.alarmApi.getAlarmsByFrequency(request).pipe(
          map(
            data => TopAlarmApiActions.loadAlarmsByFrequencySuccess({ data })
          ),
          catchError(
            (error) => of(TopAlarmApiActions.loadAlarmsByFrequencyFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private alarmApi: AlarmApiService,
  ) { }
}
