import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeviceDashboardReqModel, DeviceTableReqModel } from 'src/app/core/models/device-statistics.model';

const API_PREFIX = 'device';

@Injectable({
    providedIn: 'root'
})
export class DeviceStatisticsApiService {
    constructor(private http: HttpClient) { }
    getHierarchyDetails(request: any): Observable<any> {
        let payload = {
            tenantId: request.tenantId,
            dashboardType: "Devices",
            deviceFilters: {
                type: request.type
            },
            eventFilters: {
                sitesIds: {
                    id: request.siteId
                }
            }
        }
        return this.http.post<any>(
            `${environment.apiURL}/${API_PREFIX}/getHierarchyDetails`,
            payload,
            { headers: this.getTenantIdHeader(request.tenantId) }
        );
    }
    getDeviceOverView(request: DeviceDashboardReqModel): Observable<any> {
        return this.http.post<any>(
            `${environment.apiURL}/${API_PREFIX}/getDeviceOverView`,
            request,
            { headers: this.getTenantIdHeader(request.tenantId) }
        );
    }
    getDeviceControllerAlarmsChart(request: DeviceDashboardReqModel): Observable<any> {
        return this.http.post<any>(
            `${environment.apiURL}/${API_PREFIX}/getDeviceAlarmsChart`,
            request,
            { headers: this.getTenantIdHeader(request.tenantId) }
        );
    }
    getDeviceDetails(request: DeviceTableReqModel): Observable<any> {
        return this.http.post<any>(
            `${environment.apiURL}/${API_PREFIX}/getDeviceDetails`,
            request,
            { headers: this.getTenantIdHeader(request.tenantId) }
        );
    } 
    getDeviceAlarms(request: DeviceTableReqModel): Observable<any> {
        return this.http.post<any>(
            `${environment.apiURL}/${API_PREFIX}/getDeviceAlarms`,
            request,
            { headers: this.getTenantIdHeader(request.tenantId) }
        );
    } 
    getDeviceChildOverView(request: any): Observable<any> {
        return this.http.post<any>(
            `${environment.apiURL}/${API_PREFIX}/getDeviceChildOverView`,
            request,
            { headers: this.getTenantIdHeader(request.tenantId) }
        );
    }

    getDeviceInformation(request: any): Observable<any> {
        return this.http.post<any>(
            `${environment.apiURL}/${API_PREFIX}/getDeviceInformation`,
            request,
            { headers: this.getTenantIdHeader(request.tenantId) }
        );
    }

    getDeviceInformationHead(request: any): Observable<any> {
        return this.http.post<any>(
            `${environment.apiURL}/${API_PREFIX}/getDeviceInformationHead`,
            request,
            { headers: this.getTenantIdHeader(request.tenantId) }
        );
    }
    private getTenantIdHeader(tenantId: string): HttpHeaders {
        return new HttpHeaders().set('tenantId', tenantId);
    }
}
