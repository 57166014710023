import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISpace, Shift } from '../../models/configuration.model';

const API_PREFIX = 'filter';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationApiService {

  constructor(private http: HttpClient) { }

  getSpaces(tenantId: string , dataSetId: number): Observable<ISpace[]> {
    let params = new HttpParams();
    params = params.append('space', 'PlantPAx');
    params = params.append('dataSetId', dataSetId);
    return this.http.get<ISpace[]>(
      `${environment.apiURL}/${API_PREFIX}/getAreasGroups`,
      {
        params,
        headers: this.getTenantIdHeader(tenantId)
      },
    );
  }

  getShifts(tenantId: string): Observable<Shift[]> {
    return this.http.get<Shift[]>(
      `${environment.apiURL}/${API_PREFIX}/getShifts`,
      { headers: this.getTenantIdHeader(tenantId) }
    );
  }

  private getTenantIdHeader(tenantId: string): HttpHeaders {
    return new HttpHeaders().set('tenantId', tenantId);
  }

}
